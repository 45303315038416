<template>
  <span>
    <i :class="statusClass" class="status-indicator"></i>
    <template v-if="displayText">{{ statusText }}</template>
  </span>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
      default: 0,
      validator: status => [0, 1, 2].indexOf(status) > -1
    },
    displayText: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statusClass () {
      return [ 'is-normal', 'is-warned', 'is-banned' ][this.status]
    },
    statusText () {
      return [ 'Normal', 'Averti', 'Bloqué' ][this.status]
    }
  }
}
</script>

<style lang="scss" scoped>
.status-indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 10px;
  &.is-normal {
    background-color: $green;
  }
  &.is-warned {
    background-color: $orange;
  }
  &.is-banned {
    background-color: $red;
  }
}
</style>
