<template>
  <button class="infos">
    i
  </button>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
.infos {
  background: #D8D8D8;
  color: #FFF;
  border-radius: 100%;
  border: 0;
  width: 20px;
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
</style>
