import { getLotteryDate } from '@/pure/date'

export default {
  data () {
    return {
      counter: this.getCounter()
    }
  },
  watch: {
    '$store.getters.clientDelay': 'setCounter'
  },
  methods: {
    setCounter () {
      this.counter = this.getCounter()

      clearTimeout(this.to)

      // Loop every minutes
      this.to = setTimeout(this.setCounter, 1000 * 60)
    },
    getCounter () {
      const now = new Date(Date.now() + this.$store.getters.clientDelay)
      const allMinutes = (getLotteryDate(now).getTime() - now.getTime()) / (1000 * 60)

      const days = Math.floor(allMinutes / (60 * 24))
      const hours = Math.floor((allMinutes / 60) - days * 24)
      const minutes = Math.floor((allMinutes - (days * 24 * 60 + hours * 60)))

      return {
        days: ('0' + days).slice(-2),
        hours: ('0' + hours).slice(-2),
        minutes: ('0' + minutes).slice(-2)
      }
    }
  }
}
